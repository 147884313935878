export const dataZamowianie = [
    {
        lang: 'POL',
        message: 'Projekt chwilowo przeniesiony',
        back: '<- Powrót',
    },
    {
        lang: 'ENG',
        message: 'Project temporarily moved',
        back: '<- Back',
    },
]