export const dataLang = [
    {
        id: 1,
        lang: 'POL',
        title: 'O mnie',
        subtitle: 'Kilka słów',
        cardTitle1: 'Doświadczenie',
        cardSubtitle1: '3+ Lata niekomercyjne',
        cardTitle2: 'Kursy',
        cardSubtitle2: '10+ On-line',
        cardTitle3: 'Projekty',
        cardSubtitle3: '30+ Kompletnych',
        description: 'Jestem absolwentem Wyższej Szkoły Zarządzania i Administracji. Nie mam jeszcze doświadczenia zawodowego ale od wielu lat uczę się programowania na wielu kursach On-line czy też stacjonarnie',
        button: 'Napisz do mnie',
    },
    {
        id: 2,
        lang: 'ENG',
        title: 'About me',
        subtitle: 'A few words',
        cardTitle1: 'Experience',
        cardSubtitle1: '3+ Non-Commercial Years',
        cardTitle2: 'Courses',
        cardSubtitle2: '10+ On-line',
        cardTitle3: 'Projects',
        cardSubtitle3: '30+ Complete',
        description: 'I am a graduate of the University of Management and Administration. I do not have professional experience yet, but for many years I have been learning programming on many On-line or stationary courses',
        button: 'Write to me',
    },

]
