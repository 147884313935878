import React from 'react'
import {ZamowieniaEmail} from "../components/zamowieniaEmail/ZamowieniaEmail";

export const ZamowieniaEmailView = () => {
    return (
        <>
            <ZamowieniaEmail/>
        </>
    )
};

