import React from 'react'
import {Cv} from "../components/cv/Cv";

export const CvView = () => {
    return (
        <>
            <Cv />
        </>
    )
};

